import { Carousel } from 'bootstrap';

export function initParallax() {
    "use strict";

    const elementsWithBackground = document.querySelectorAll('div[data-type="background"]');

    window.addEventListener('scroll', () => {
        const scrollTop = window.scrollY;

        elementsWithBackground.forEach(element => {
            const speed = element.getAttribute('data-speed');
            const yPos = -(scrollTop / speed);
            const coords = `50% ${yPos}px`;
            element.style.backgroundPosition = coords;
        });
    });
}

initParallax();
